@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.site-logo {
  display: block;
  max-width: 250px;
  margin: auto;
}

.scan-holder {
  position: relative;
}

.validation-overlay {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .successIcon,
  .failIcon {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    -webkit-animation: fade-in 1s; /* Safari 4+ */
    -moz-animation: fade-in 1s; /* Fx 5+ */
    -o-animation: fade-in 1s; /* Opera 12+ */
    animation: fade-in 1s; /* IE 10+, Fx 29+ */
  }
  .successIcon {
    display: block;
    color: #4caf50;
    border: 2px solid #4caf50;
  }

  .failIcon {
    display: block;
    color: #dc3545;
    border: 2px solid #dc3545;
  }

  h3 {
    text-transform: uppercase;
  }

  .text-white {
    color: #fff;
  }

  .success {
    color: #4caf50;
  }

  .failed {
    color: #dc3545;
  }
}

.greybox {
  padding: 24px;
  background-color: #fafafa;
  margin-bottom: 20px;
}

// .room-bookings {
//   margin-top: 24px;
//   margin-bottom: 24px;

//   > section {
//     padding: 2rem;
//   }
//   > section:nth-of-type(even) {
//     background-color: rgba(0, 0, 0, 0.3);
//   }

//   @media (min-width: 648px) {
//     margin-top: 48px;
//     margin-bottom: 48px;
//   }
// }

.no-res-msg{
  margin: 2rem 0 !important;
  padding-bottom: 2rem;
}
